.accommodations {
  background-color: $secondary_color;
  color: $primary_color;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  h2 {
    margin-bottom: 0;
  }

  &_slick {
    margin-top: 4rem;
    display: flex;
    flex-direction: column-reverse;

    &_container {
      position: relative;
      margin-bottom: 2rem;
      width: 100%;
      z-index: 1;

      &_title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 3rem;
        margin-bottom: 3rem;

        h3 {
          text-align: center;
          font-size: 1.25rem;
          font-weight: 400;
          letter-spacing: 0.3rem;
        }
      }

      &_image {
        width: 100%;
        max-height: 600px;
        margin-bottom: 8rem;

        @include size_XS {
          height: 300px;
          margin-bottom: 0rem;
        }

        object-fit: cover;
      }

      &_box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem;
        position: absolute;
        bottom: 0;

        @include size_XS {
          max-height: unset;
          max-width: calc(380px - 4rem);
          padding: unset;
          position: relative;
          box-shadow: unset;
        }

        left: 50%;
        transform: translateX(-50%);
        background-color: $secondary_color;
        max-height: 180px;
        height: 100%;
        max-width: 380px;
        width: 100%;
        border-radius: 10px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        &_title {
          font-weight: 500;
          letter-spacing: 4px;
          text-transform: uppercase;
          color: $complementary_color;

          @include size_XS {
            padding: 1rem 0;
          }
        }

        &_links {
          display: flex;
          justify-content: space-between;
          gap: 20px;

          div,
          a {
            width: fit-content;
            position: relative;
            cursor: pointer;
            opacity: 1;

            &::after {
              content: '';
              position: absolute;
              bottom: -4px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: $primary_color;
              opacity: 1;
              transition: opacity 300ms, transform 300ms;
              transform: scale(0);

              @include size_XS {
                transform: scale(1);
              }

              transform-origin: center;
            }

            &:hover::after,
            &:focus::after {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}

.slick-list {
  overflow: unset;
  overflow-x: clip;
}

@include size_XS {
  .title_underline_complementary_small {
    &::after {
      display: none;
    }
  }
}
