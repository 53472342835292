.services {
    background-color: $secondary_color;
    color: $primary_color;
    padding-top: 2rem;
    padding-bottom: 4rem;
  
    &_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
  
      &_details {
        padding-top: 2rem;
        display: grid;
        max-width: 900px;
        grid-template-columns: 1fr 1fr 1fr;
  
        @include size_M{
          grid-template-columns: 1fr 1fr;
        }
  
        @include size_S{
          grid-template-columns: 1fr;
        }
  
        justify-items: center;
        gap: 2rem;
        margin: 0 auto;
  
        div {
          display: grid;
          grid-template-columns: 10% 90%;
          width: 100%;
           
          gap: 1rem;
        }
      }
    }
  }
  