.footer {
  background: linear-gradient(180deg, $primary_color, $complementary_color 200%);
  color: $secondary_color;
  padding-top: 4rem;

  .svg_color {
    fill: $secondary_color;
  }

  p {
    color: $secondary_color;
    opacity: 0.75;
    line-height: 1rem;
    margin: 0;
  }

  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &_logo {
      margin-bottom: 6rem;
      cursor: pointer;

      img {
        height: 120px;
      }
    }

    &_contact {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1.5rem;
      margin-bottom: 6rem;
    }

    &_bottom {
      p {
        font-size: 0.75rem;
        padding-top: 1rem;
      }
    }
  }
}
