$transition: 250ms ease;
$transition_slow: 500ms ease;

$primary_color: #404047;
$secondary_color: #F0F0F0;
$complementary_color: #336699;
// $complementary_color: #7692FF;

:export {
    primaryColor: $primary_color;
    secondaryColor: $secondary_color;
    complementaryColor: $complementary_color;
}
